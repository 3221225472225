<template>
    <v-sheet
        elevation="3"
    >
        <v-toolbar class="primary" dark dense>
            <v-toolbar-title >
                Curvas de temperatura y frecuencia cardíaca de {{estaFecha}}
            </v-toolbar-title>
        </v-toolbar>

        <apexchart height='300px' type='line' :options="options" :series="series"/>
    </v-sheet>
    
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    
    components: {
        'apexchart':VueApexCharts,
    },
    props:{
        items:Array,
        estaFecha:String,
        options:Object,

        

    },

    
    
    data:()=>({
        
        //options:{},
        series:[],
        fc:[],
        temp:[],

        horarios: [
            '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
            '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
            '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
        ],



        




    }),

    methods: {

       calculaSignos(){
           
           //this.fc = []
           //this.temp = []
            //calcula FC
            let fcData = []
            let tempData = []

            let fcArray=[]
            let tempArray=[]

            fcData = this.items.filter(ent=>ent.signo=='fc')
            tempData = this.items.filter(ent=>ent.signo=='temp')

            fcData.forEach((ent) => {
                let fechaEntrada = this.moment(ent.fechaRegistro, 'DD/MM/YYYY HH:mm')
                let hora = fechaEntrada.format('H')
                if (hora>=0 && hora < 8){
                    fechaEntrada == fechaEntrada.add(1, "d")
                }
                fcArray.push([
                    fechaEntrada.toDate().getTime(),
                    ent.valor
                ])
            })

            tempData.forEach((ent) => {
                let fechaEntrada = this.moment(ent.fechaRegistro, 'DD/MM/YYYY HH:mm')
                let hora = fechaEntrada.format('H')
                if (hora>=0 && hora < 8){
                    fechaEntrada == fechaEntrada.add(1, "d")
                }
                tempArray.push([
                    fechaEntrada.toDate().getTime(),
                    ent.valor
                ])
            })
            this.fc = fcArray
            this.temp = tempArray

            //console.log(this.temp)
            //console.log(this.fc)

           this.series = [
                {
                    name: "Temperatura",
                    //data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                    data:this.temp
                },
                {
                    name: "Frecuencia cardíaca",
                    //data: [20, 15, 80, 30, 16, 95, 88, 16, 100]
                    data:this.fc
                },

            ]
            //this.optionsGraph()
        },
        
        
    },

    computed:{
        
        
    },

    watch:{
        items(){
            this.calculaSignos()
        },
    }
      
}
</script>

<style>
  
</style>