<template>
    <div class="mx-1">
        <somato-curvas
            class="mb-2"
            :items="registrosSomato"
            :estaFecha="estaFecha"
            :options="optionsGraph"
        />

        <somato-tabla
            class="mb-2"
            :estaFecha="estaFecha"
            :internamiento="internamiento"
            :items="registrosSomato"
            :signosList="signosList"
            @signoAgregado='cargaRegistros'
        />
    </div>
</template>

<script>
import SomatoCurvas from '@/components/hospital/enfe/somato/SomatoCurvas'
import SomatoTabla from '@/components/hospital/enfe/somato/TablaSignos'


export default {
    mounted(){
        this.cargaRegistros()
    },
    components:{
        'somato-curvas':SomatoCurvas,
        'somato-tabla': SomatoTabla,
    },
    props:{
        estaFecha: String,
        internamiento: Number,
    },
    data: ()=>({
        dialogSignos:false,
        dialogSignosTodos:false,
        paramSign:{},
        registrosSomato:[],
        temp:[],
        fc:[],
        selectSigno:{},
        signosList:[
            {signo: 'peso',min: .5,max: 500,nombreLargo:'Peso (Kg)', tipo:'s'},
            {signo: 'talla',min: 30,max: 250,nombreLargo:'Talla (Cm)', tipo:'s'},
            {signo: 'temp',min: 33,max: 43,nombreLargo:'Temperatura (ºC)', tipo:'s'},
            {signo: 'fc',min: 20,max: 220,nombreLargo:"Frec. cardiaca (X')", tipo:'s'},
            {signo: 'fr',min: 5,max: 220,nombreLargo:"Frec. resp. (X')", tipo:'s'},

            {signo: 'ta',min: 0,max: 100,nombreLargo:"T. Artetial (mmHg)", tipo:'s'},
            {signo: 'tam',min: 5,max: 200,nombreLargo:"T. A. Media (mmHg)", tipo:'s'},

            {signo: 'taIn',min: 0,max: 100,nombreLargo:"T. Artetial INVASIVA(mmHg)", tipo:'s'},
            {signo: 'taInM',min: 5,max: 200,nombreLargo:"T. A. media INVASIVA(mmHg)", tipo:'s'},

            {signo: 'pvc',min: 0,max: 30,nombreLargo:"Presión Venosa Central", tipo:'s'},

            {signo: 'sat',min: 0,max: 100,nombreLargo:'Saturación (%)', tipo:'s'},
            {signo: 'glic',min: 0,max: 600,nombreLargo:'Glicemia (mm/dl)', tipo:'s'},
            {signo: 'cap',min: .1,max: 10,nombreLargo:'Llen. capilar (seg)', tipo:'s'},
            {signo: 'pcef',min: 5,max: 80,nombreLargo:'Per. cefálico (cm)', tipo:'s'},
            {signo: 'pabd',min: 15,max: 150,nombreLargo:'Per. abdom. (cm)', tipo:'s'},
            {signo: 'imc',min: 5,max: 50,nombreLargo:'Ind. masa corp.', tipo:'s'},
            {signo: 'scor',min: 0,max: 2.5,nombreLargo:'Sup. corporal (m2)', tipo:'s'},

            {signo: 'tipov',min: 0,max: 1000,nombreLargo:'Modo ventilatorio', tipo:'v'},
            {signo: 'frecv',min: 0,max: 1000,nombreLargo:'Frecuencia', tipo:'v'},
            {signo: 'tinsp',min: 0,max: 1000,nombreLargo:'T. Insp', tipo:'v'},
            {signo: 'pinsp',min: 0,max: 1000,nombreLargo:'P. Insp', tipo:'v'},
            {signo: 'peep',min: 0,max: 1000,nombreLargo:'P.E.E.P.', tipo:'v'},
            {signo: 'fio2',min: 0,max: 1000,nombreLargo:'F.I. O2', tipo:'v'},
            {signo: 'vcor',min: 0,max: 1000,nombreLargo:'Volumen corriente', tipo:'v'},
        ],
        tablaSignoShow:false,
    }),
    methods:{
        abreNuevoSigno(){

            this.selectSigno.internamiento = this.$route.params.id
            this.selectSigno.estaFecha = this.estaFecha,

            this.dialogSignos=true
            this.dialogSignos = true
        },

        async cargaRegistros(){
            let datos = {
                fecha:this.estaFecha,
                internamiento:this.$route.params.id
            }
            try{
                let reg = await this.$http({
                    method:'GET',
                    url:'/enfermeria/cargaSignos',
                    params: datos
                })
                this.registrosSomato=reg.data.lista

                // Para la gráfica de frecuencia cardiaca
                this.fc = reg.data.grafica.filter(function(grafico) {
                    return grafico.signo == "fc"
                });

                // Para la gráfica de temperatura
                this.temp = reg.data.grafica.filter(function(grafico) {
                    return grafico.signo == "temp"
                });

            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
            }

        },
        

        filtraDatos(tipo){
            return this.registrosSomato.filter(function(grafico) {
                return grafico.signo == tipo
            });
        },
        muetraDatosSomato(item){
            console.log(item)
        }
    },


    computed:{
        fechaDe(){
            return this.moment(this.estaFecha+' 08:00', 'DD-MM-YYYY HH:mm').toDate().getTime()
        },
        fechaA(){
            return this.moment(this.estaFecha+' 08:00', 'DD-MM-YYYY HH:mm').add(1,'d').toDate().getTime()
        },

        
        optionsGraph(){
            let opts = {
                
                chart: {
                    toolbar:{
                        show: false,
                    },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.7
                    },
                    zoom: {
                        enabled: false
                    },               
                },

                colors: ['#EF9A9A', '#90CAF9'],
                
                dataLabels: {
                    enabled: true
                },

                markers: {
                    size: 1,
                    hover: {
                        sizeOffset: 5
                    }
                },
                stroke: {
                    width:[2,2],
                    curve: 'straight',
                    show: true,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    },
                },

                xaxis: {
                    type:'datetime',
                    min: this.fechaDe,
                    max: this.fechaA,
                    tickAmount:6,
                    axisTicks: {
                        show: true
                    },
                    labels:{
                        datetimeUTC: false,
                        formatter: (value) => {
                            return this.moment(value).format('HH:00')
                        }
                    },
                },
                yaxis: [
                    {
                        seriesName:"Temperatura",
                        opposite: true,
                        min: 35,
                        max: 42,

                        axisTicks: {
                            show: true
                        },

                        axisBorder: {
                            show: true,
                            color: "#EF9A9A"
                        },

                        labels: {
                            style: {
                                colors: "#EF9A9A"
                            }
                        },

                        title: {
                            text: "Temperatura",
                            style: {
                                color: "#EF9A9A"
                            }
                        },
                    
                    },

                    {
                        seriesName:"Frecuencia cardíaca",
                        min: 0,
                        max: 200,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#90CAF9"
                        },
                        labels: {
                            style: {
                                colors: "#90CAF9"
                            }
                        },
                        title: {
                            text: "Frecuencia cardíaca",
                            style: {
                                color: "#90CAF9"
                            }
                        }
                    }
                ],
            }
            return (opts)
        },
        
    },
    watch:{
        estaFecha(){
            this.cargaRegistros()
        },
        selectSigno(){
            if (this.selectSigno){
                this.tablaSignoShow=true
                //console.log('cargando')
                this.selectSigno.internamiento = this.$route.params.id
                this.selectSigno.estaFecha = this.estaFecha
            } else {
                this.tablaSignoShow=false
            }
        }
    },

}
</script>

<style>

</style>