<template>
    <v-sheet
        elevation="3"
    >

        <v-tabs
            v-model="tab"
            centered
            icons-and-text
            background-color="primary"
            dark
        >
            <v-tab href="#tab-mat">
                Matutino
                <v-icon>mdi-weather-sunset-up</v-icon>
            </v-tab>

            <v-tab href="#tab-ves">
                Vespertino
                <v-icon>mdi-weather-sunny</v-icon>
            </v-tab>

            <v-tab href="#tab-noc">
                Nocturno
                <v-icon>mdi-weather-night</v-icon>
            </v-tab>
        </v-tabs>

        <v-btn
            class="my-2 ml-6" 
            rounded
            color="success"
            @click="dialogSignosTodos=true"
        >
            <v-icon  left>mdi-list-status</v-icon>
            Agrega varios
        </v-btn>

        <v-menu offset-y >
            <template v-slot:activator="{ on }">

                <v-btn
                    class="my-2 ml-6" 
                    rounded
                    color="primary"
                    v-on="on"
                >
                    <v-icon  left>mdi-file-plus-outline</v-icon>
                    Agrega simple
                </v-btn>
            </template>

            <v-list dense>
                <v-list-item
                    class="my-n2"
                    v-for="item in signosList"
                    :key="item.signo"
                    @click="nuevoSignoSolo(item)"
                    dense
                >
                    <v-list-item-title>
                        {{ item.nombreLargo }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-tabs-items
            v-model="tab"
        >
            <v-tab-item
                v-for="turno in ['mat', 'ves', 'noc']"
                :key="turno"
                :value="'tab-' + turno"
                class="px-5 pb-5"
            >
                    <v-simple-table
                        dense
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th
                                        class="text-center white--text"
                                        :style="{'background-color': turnosColores[turno]}"
                                    >
                                        <span class="body-2 font-weight-black">Signo</span>
                                    </th>
                                    <th
                                        class="text-center white--text"
                                        v-for="itm in horarios[turno]"
                                        :key="itm"
                                        width="7%"
                                        :style="{'background-color': turnosColores[turno]}"
                                    >
                                        <span>
                                            {{itm}}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-for="sgn in signosListSomato"
                                :key="sgn.signo"
                            >
                                <tr>
                                    <td
                                        class="tablaBorde"
                                    >
                                        <b>{{sgn.nombreLargo}}</b>
                                    </td>
                                    <td
                                        v-for="itm in horarios[turno]"
                                        :key="itm"
                                        class="tablaBorde"
                                    >
                                        <div
                                            v-for="valor in getDatos(sgn.signo, itm)"
                                            :key="valor.id"
                                        >
                                            <div
                                                :style="{'border-style': 'solid', 'border-width': '1px'}"
                                                class="rounded-pill text-center text-caption my-1"
                                                @click="muestraDetalle(valor)"
                                            >
                                                {{valor.valor}}
                                            </div>
                                        </div>
                                        <v-icon
                                            v-if="getDatos(sgn.signo, itm).length == 0"
                                            small
                                            @click="nuevoSignoTabla(sgn, itm)"
                                        >
                                            mdi-plus
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>


                            <tbody>
                                <tr>
                                    <th
                                        class="text-center white--text"
                                        :style="{'background-color': '#BDBDBD'}"
                                        :colspan="horarios[turno].length + 1"
                                    >
                                        <span class="body-2 font-weight-black">Parámetros de ventilador</span>
                                    </th>
                                </tr>
                            </tbody>
                            <tbody
                                v-for="sgn in signosListVent"
                                :key="sgn.signo"
                            >
                                <tr>
                                    <td
                                        class="tablaBorde"
                                    >
                                        <b>{{sgn.nombreLargo}}</b>
                                    </td>
                                    <td
                                        v-for="itm in horarios[turno]"
                                        :key="itm"
                                        class="tablaBorde"
                                    >
                                        <div
                                            v-for="valor in getDatos(sgn.signo, itm)"
                                            :key="valor.id"
                                        >
                                            <div
                                                :style="{'border-style': 'solid', 'border-width': '1px'}"
                                                class="rounded-pill text-center text-caption my-1"
                                                @click="muestraDetalle(valor)"
                                            >
                                                {{valor.valor}}
                                            </div>
                                        </div>
                                        <v-icon
                                            v-if="getDatos(sgn.signo, itm).length == 0"
                                            small
                                            @click="nuevoSignoTabla(sgn, itm)"
                                        >
                                            mdi-plus
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
            </v-tab-item>
        </v-tabs-items>

        <somato-agrega-signo
            :estaFecha="estaFecha"
            :estaHora="estaHora"
            :internamiento="internamiento"
            :dialogSignos.sync="dialogSignos"
            :listaSign="filtraDatos(selectSigno.signo)"
            :paramSign='selectSigno'
            @signoAgregado='$emit("signoAgregado")'
        />

        <somato-agrega-varios-signos
            :dialogSignosTodos.sync='dialogSignosTodos'
            :internamiento="internamiento"
            :items="signosList"
            :estaFecha="estaFecha"
            :registros="items"
            @signoAgregado='$emit("signoAgregado")'
        />

        <somato-detalle
            :nuevaEntradaDialog.sync="entradaDialog"
            :datos="datosEntrada"
            @borrado='$emit("signoAgregado")'
        />

    </v-sheet>
</template>

<script>

export default {
    props:{
        estaFecha:String,
        internamiento:Number,
        items:Array,
        signosList:Array,
        permiteAgrega:Boolean,
        //signosList:Array,
    },

    components:{
        'somato-agrega-signo':()=>import('@/components/hospital/enfe/somato/AgregaSigno'),
        'somato-detalle':()=>import('@/components/hospital/enfe/somato/DetalleSigno'),
        'somato-agrega-varios-signos':()=>import('@/components/hospital/enfe/somato/AgregaVariosSignos')
    },

    data:()=>({
        signos:[],
        tab:0,

        dialogSignos:false,
        dialogSignosTodos:false,
        selectSigno:{},
        estaHora:'',

        entradaDialog:false,
        datosEntrada:{},

        turnosColores:{
            mat:'#81D4FA',
            ves:'#A5D6A7',
            noc:'#EF9A9A',
        },
        horarios:{
            mat: [
                '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
            ],
            ves: [
                '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
            ],
            noc: [
                '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
            ],
        }
    }),

    methods:{

        filtraDatos(tipo){
            return this.signosList.filter((dt)=>{
                dt.signo == tipo
            })
        },

        getDatos(signo, hora){
            let entradas=[]
            let soloHora = hora.split(':')[0]
            let esteSigno = this.items.filter(ent=>ent.signo==signo)

            esteSigno.forEach((ent)=>{
                //console.log(ent)
                let estaEntradaHora = ent.hora.split(':')[0]
                if (estaEntradaHora == soloHora){
                    entradas.push(ent)
                }
            })
            return (entradas)
        },
        muestraDetalle(datos){
            this.datosEntrada=datos
            this.entradaDialog=true
        },
        nuevoSignoSolo(signo){
            this.selectSigno = signo
            this.estaHora = null
            this.dialogSignos=true
        },
        nuevoSignoTabla(signo, hora){
            this.selectSigno = signo
            this.estaHora = hora
            this.dialogSignos=true
        }
    },

    computed:{
        signosListSomato(){
            return (this.signosList.filter(ss=>ss.tipo=='s'))
        },
        signosListVent(){
            return (this.signosList.filter(ss=>ss.tipo=='v'))
        }
    }
}
</script>

<style>
.tablaBorde{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
</style>